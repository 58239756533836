<template>
    <div :id="id_index"></div>
</template>

<script>
const AMap = window.AMap;
var map, geocoder, marker, markerlist = [];
export default {
    name: "Amap",
    // 父组件参数
    props: {
        idindex: {
            type: [String, Number],
            default: '0'
        },
        value: null,
        noCreatedMap: null,     // 初始化不创建地图
        geolocation: null,      // 定位
        clickMap: null,         // 点击地图事件

    },
    data() {
        return {
            id_index: 'amap_container',
            markerlist: []
        }
    },
    // 创建
    created() {
        this.id_index = 'amap_container_' + this.$props.idindex      // 设置id
        console.log('this.id_index', this.id_index)
    },
    // 安装
    mounted() {
        this.init()
    },
    methods: {
        /**
         * 初始化
         * */
        init() {
            // 判断是否需要渲染地图
            if (this.Tool.is_empty(this.noCreatedMap)) {
                this.createdMap();  // 创建地图
            }
        },

        /**
         * 渲染地图
         *
         * @param {Object} config 配置信息，参考高德地图接口
         * */
        createdMap(config = { resizeEnable: true, zoom: 10 }) {
            let thi = this
            // 加载地图
            let id_index = this.id_index
            map = new AMap.Map(id_index, config);
            // 地图加载完成后执行
            map.on('complete', () => {
                // 地图图块加载完成后触发
                console.log('地图加载完成')
                // 插入比例尺
                AMap.plugin([
                    'AMap.Scale',
                    'AMap.Geocoder',
                ], function () {
                    // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
                    map.addControl(new AMap.Scale());
                    geocoder = new AMap.Geocoder()
                });

                // 判断是否需要自动定位
                if (!thi.Tool.is_empty(thi.geolocation)) {
                    // 定位
                    console.log('自动定位')
                    AMap.plugin([
                        'AMap.Geolocation',
                    ], function () {
                        // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
                        let geolocation = new AMap.Geolocation({
                            enableHighAccuracy: true,//是否使用高精度定位，默认:true
                            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                            zoomToAccuracy: false,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                            buttonPosition: 'RB'
                        })
                        AMap.event.addListener(geolocation, 'complete', thi.geolocation)
                        AMap.event.addListener(geolocation, 'error', thi.geolocation)
                        map.addControl(geolocation)
                        geolocation.getCurrentPosition()    // 开始定位
                    });
                }

                // 判断是否有点击事件
                if (!thi.Tool.is_empty(thi.clickMap)) {
                    console.log('点击事件')
                    map.on('click', function (ev) {
                        thi.clickMap && thi.clickMap(ev)
                    });
                }
            });
        },

        createMaker(item) {
            let { markerlist } = this;
            const { lng, lat } = item.location;
            const position = new AMap.LngLat(lng, lat);
            const marker = new AMap.Marker({
                position,        //位置经纬度
            });
            if (markerlist.length > 0) {
                map.remove(markerlist);
                // 移除markerlist第一条数据
                markerlist.shift();
            }
            markerlist = markerlist.concat(marker);
            map.setZoom(16);
            map.setCenter(position);
            map.add(markerlist);
            this.markerlist = markerlist;
        },



        /**
         * 地图定点
         *
         * @param {Array} position 坐标数据，例：[121.532215, 31.113243,'上海'] 或者[[121.532215, 31.113243,'上海']]
         * @param {Boolean} [isadd = false] 是否追加定点
         * */
        marker(position, isadd = false) {
            // console.log('position', position)
            // 判断是否追加数据
            if (!isadd && marker) {
                map.remove(marker);
                marker = undefined
            }

            if (!isadd && markerlist) {
                map.remove(markerlist);
                markerlist = []
            }

            // 判断单条还是多条数据
            if (position[0][0] === undefined) {     // 单条数据
                let [lng, lat, title = ''] = position
                marker = new AMap.Marker({
                    position: [lng, lat],        //位置经纬度
                    title: title
                })
                map.add(marker);    // 定点单个
            } else {    // 多条数据
                for (let v of position) {
                    let [lng, lat, title = ''] = v
                    markerlist.push(new AMap.Marker({
                        position: [lng, lat],        //位置经纬度
                        title: title
                    }))

                }
                map.add(markerlist);    // 定点多个
            }
        },

        // 关键词查询方法
        autoComplete(config = { query: '' }, callbackfun, errorfun) {
            AMap.plugin('AMap.AutoComplete', () => {
                const autoOptions = {
                    city: config.city_code ? config.city_code : '全国',
                    citylimit: true
                };
                const autoComplete = new window.AMap.AutoComplete(autoOptions);
                autoComplete.search(config.query, (status, result) => {
                    if (status === 'complete' && result.info === 'OK') {
                        callbackfun && callbackfun(result.tips);
                    } else {
                        errorfun && errorfun(result);
                    }
                })
            })
        },

        address2position(config = { address: '' }, callbackfun, errorfun) {
            AMap.plugin('AMap.Geocoder', () => {
                const geocoder = new window.AMap.Geocoder({
                    city: config.city ? config.city : '全国',
                    citylimit: true
                });
                geocoder.getLocation(config.address, (status, result) => {
                    if (status === 'complete' && result.info === 'OK') {
                        callbackfun && callbackfun(result.geocodes[0]);
                    } else {
                        errorfun && errorfun(result);
                    }
                })
            })
        },

        /**
         * 地址转坐标
         *
         * @param {String} address 地址信息
         * @param {Fun} callbackfun 回掉函数
         * */
        // address2position(address = '', callbackfun) {
        //     geocoder.getLocation(address, function (status, result) {
        //         if (status === 'complete' && result.info === 'OK') {
        //             let geocodes = result.geocodes[0]
        //             let addressComponent = geocodes.addressComponent
        //             let info = {
        //                 region_code: geocodes.adcode,
        //                 address: addressComponent.township + addressComponent.street + addressComponent.streetNumber,
        //                 country: addressComponent.country,
        //                 province: addressComponent.province,
        //                 city: addressComponent.city,
        //                 county: addressComponent.district,
        //                 addressall: geocodes.formattedAddress,
        //                 lng: geocodes.location.lng,
        //                 lat: geocodes.location.lat,
        //             }
        //             callbackfun && callbackfun(info)
        //         } else {
        //             console.log('根据地址查询位置失败')
        //             callbackfun && callbackfun(false)
        //         }
        //     });
        // },

        /**
         * 坐标转地址
         *
         * @param {Array} lnglat 坐标信息
         * @param {Fun} callbackfun 回掉函数
         * */
        position2address(lnglat = []) {
            geocoder.getAddress(lnglat, function (status, result) {
                console.log(status, result)
                // if (status === 'complete' && result.info === 'OK') {
                //     let addressComponent = result.regeocode.addressComponent
                //     let info = {
                //         region_code: addressComponent.adcode,
                //         address: addressComponent.township + addressComponent.street + addressComponent.streetNumber,
                //         country: addressComponent.country,
                //         province: addressComponent.province,
                //         city: addressComponent.city,
                //         county: addressComponent.district,
                //         addressall: result.regeocode.formattedAddress,
                //         lng: lnglat[0],
                //         lat: lnglat[1],
                //     }
                //     callbackfun && callbackfun(info)
                // } else {
                //     console.log('根据坐标查询位置失败')
                //     callbackfun && callbackfun(false)
                // }
            })
        }
    }
}
</script>

<style scoped>
#amap_container {
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    height: 100%;
}
</style>
